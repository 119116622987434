@keyframes load {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.App {
  min-height: 100vh;
  background-color: #191919;
  z-index: 0;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  top: 0;
  background-color: #191919;
  z-index: 1000;
}

nav p {
  margin-right: auto;
  color: #c85b02;
  border-bottom: 1px solid #191919;
  transition: all 0.2s;
  cursor: pointer;
}

nav p:hover {
  border-bottom: 1px solid #c85b02;
}

nav a,
nav p,
nav aside p {
  font-family: "Sora", sans-serif;
  font-weight: 300;
  text-decoration: none;
  margin: 0 10px;
}

nav aside a,
nav aside p {
  color: #d3a20d;
  border-bottom: 1px solid #191919;
  transition: all 0.2s;
  margin: 0 10px;
}

nav aside a:hover,
nav aside p:hover {
  border-bottom: 1px solid #d3a20d;
}

#menu-desktop {
  display: flex;
}

#menu-mobile {
  display: none;
  width: 16px;
  height: 16px;
  padding: 2px 0;
  box-sizing: border-box;
  color: #d3a20d;
}

.about {
  max-width: 1280px;
  width: 90vw;
  margin: 40px auto;
  display: grid;
  padding: 10px;
  box-sizing: border-box;
  grid-gap: 20px;
  grid-template-areas:
  "img img title title"
  "img img about about"
}


.about h2, .about p, .about img {
  color: #c85b02;
  font-family: "Sora", sans-serif;
}

.about h2 {
  grid-area: title;
  align-self: end;
}

.about p {
  grid-area: about;
  align-self: start;
}

.about img {
  grid-area: img;
  display: block;
  object-fit: cover;
  max-width: 480px;
  width: 40vw;
  max-height: 480px;
  height: 40vw;
  border-radius: 100%;
}

main {
  margin-top: 30px;
}

.grid-container .name {
  font-family: "Sora", sans-serif;
  color: #c85b02;
  background-color: rgba(25, 25, 25, 0.8);
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  font-size: 36px;
  position: sticky;
  top: 40px;
  z-index: 1001;
}

.grid-container {
  position: relative;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0.25vw;
  width: 100vw;
  box-sizing: border-box;
  margin: 5px auto 0;
  height: auto;
}

.grid section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.loading {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1080px;
  margin: 0 auto;
  grid-gap: 10px;
  animation: load 0.5s ease-in;
}

.grid-item img {
  height: auto;
  transition: all 0.2s;
  object-fit: cover;
}

.grid-item {
  transition: all 0.2s;
  width: 24vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
  padding: 0.25vw;
  animation: load 0.5s ease-in;
}

@keyframes loading {
  0% {
    background-color: #c85b02;
  }
  50% {
    background-color: #d3a20d;
  }
  100% {
    background-color: #c85b02;
  }
}

.loading {
  animation: loading 5s ease-in-out infinite;
}

.grid-item div {
  opacity: 1;
  background-color: #191919;
  width: fit-content;
  transition: all 0.2s;
  font-family: "Sora", sans-serif;
  font-weight: 300;
  color: #d3a20d;
  padding: 5px;
  margin: 5px;
  border: 1px solid #d3a20d;
  transition: all 0.2s;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
}

footer {
  width: 100vw;
  height: 84px;
  background-color: #191919;
  color: #d3a20d;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
}

footer:hover {
  background-color: #d3a20d;
  color: #191919;
}

footer i {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  font-size: 36px;
  text-align: center;
  position: absolute;
  transition: all 0.2s ease-out;
  bottom: 18px;
}

footer:hover > i {
  bottom: 54px;
}

@media (hover: hover) and (min-width: 600px) {
  .grid-item img:hover {
    transform: scale(1.1);
    z-index: 2;
  }

  .grid-item img:hover + div {
    transform: scale(1.1);
    z-index: 3;
  }

}

@media screen and (max-width: 1080px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-item {
    width: 33vw;
  }
}

@media screen and (max-width: 768px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-item {
    width: 49vw;
  }
}

@media screen and (max-width: 600px) {
  #menu-mobile {
    display: flex;
  }

  aside {
    position: fixed;
    background: #191919;
    display: flex;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    z-index: -1000;
    transition: all 0.5s ease-in-out;
  }

  nav aside #menu-mobile {
    position: fixed;
    top: 10px;
    right: 10px;
    color: #d3a20d;
  }

  nav aside a,
  nav aside p {
    color: #d3a20d;
    border-bottom: 1px solid #191919;
    font-size: 32px;
    text-align: center;
  }

  nav p:hover {
    border-bottom: 1px solid #d3a20d;
  }

  nav aside a:hover,
  nav aside p:hover {
    border-bottom: 1px solid #d3a20d;
  }

  .about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .about h2, .about p, .about img {
    align-self: initial;
  }
  
  .about img {
    max-width: 60vw;
    max-height: 60vw;
    width: 60vw;
    height: 60vw;
  }

  .grid {
    grid-template-columns: 1fr;
  }

  .grid-item {
    width: 90vw;
  }

  .grid-item div {
    opacity: 1;
  }

  .highlight {
    width: 100vw !important;
    height: 100vh !important;
  }
}
